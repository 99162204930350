import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import {ToastProvider} from "react-toast-notifications";
import 'bootstrap/dist/css/bootstrap.min.css';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Camere = React.lazy(() => import("./components/pages/Camere"));
const Camera = React.lazy(() => import("./components/pages/Camera"));
const Galerie = React.lazy(() => import("./components/pages/Galerie"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const GDPR = React.lazy(() => import("./components/pages/GDPR"));
const Confidential = React.lazy(() => import("./components/pages/Confidential"));
const Login = React.lazy(() => import("./components/pages/Login"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function App() {
  return (
    <Router>
      <ToastProvider>
        <Suspense fallback={<div></div>}>
          <ScrollToTop>
            <Preloader />
            <Route path="/" exact component={Home} />
            <Route path="/camere" exact component={Camere} />
            <Route path="/camera/:id" exact component={Camera} />
            <Route path="/galerie" exact component={Galerie} />
            <Route path="/despre" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/gdpr" exact component={GDPR} />
            <Route path="/confidential" exact component={Confidential} />
            <Route path="/admin" exact component={Login} />
            <CookieConsent
              enableDeclineButton 
              acceptOnScroll
              acceptOnScrollPercentage="25"
              declineButtonText="Nu sunt de acord"
              declineButtonStyle={{ background:"Gray", color: "White", borderRadius:"10px" }}
              location="bottom"
              buttonText="De acord"
              cookieName="consent-cookie"
              style={{ background: "#bead8e", color: "#000", borderRadius:"25px 25px 0 0" }}
              buttonStyle={{ background:" #F6BE00", color: "Black", borderRadius:"10px", margin:"30px 50px 0px 0px" }}
              buttonClasses="p-2 ps-5 pe-5"
              declineButtonClasses="p-2"
              expires={150}
            >
              <div className='row ps-5'>
                <div className='col-md-12' style={{ color:"black", fontSize: 16 }}>Acest site utilizează cookies-uri</div>
                <div className='col-md-12' style={{ color:"black", fontSize: 16 }}>Utilizând scroll în conținutul site-ului vom considera că acceptați cookies-urile</div>
              </div>
            </CookieConsent>

          </ScrollToTop>
        </Suspense>
      </ToastProvider>      
    </Router>
  );
}

export default App;
